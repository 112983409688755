function FileMenu({}) {
  return (
    <>
      <div className="item-container">
        <i className="fas fa-share-square"></i>
        Share
      </div>
      <div className="item-container">
        <i className="fas fa-save"></i>
        Save
      </div>
      <div className="item-container">
        <i className="fas fa-trash"></i>
        Delete
      </div>
      <div className="item-container">
        <i className="fas fa-edit"></i>
        Rename
      </div>
      <div className="item-container">
        <i className="fas fa-folder"></i>
        Move
      </div>
      <div className="item-container">
        <i className="fas fa-plus-square"></i>
        New
      </div>
    </>
  );
}

export default FileMenu;
