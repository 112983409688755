import './Analytics.scss';

function Analytics({}) {
  return (
    <div className="Analytics">
      Analytics
    </div>
  );
}

export default Analytics;
