import cloneDeep from 'lodash.clonedeep';
import { defaultForm } from '../../components/FormBuilder/formConfig';
import {
  SET_FORM,
} from '../actions/types';

const defaultState = cloneDeep(defaultForm);

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FORM:
      return action.payload;
    default:
      return state;
  }
};
