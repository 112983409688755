import {
  SET_ORGANIZATION_USERS,
} from './types';

export const setOrganizationUsers = (users) => {
  return {
    type: SET_ORGANIZATION_USERS,
    payload: users,
  };
};
