import {
  SET_FORM,
} from './types';

export const setForm = (form) => {
  return {
    type: SET_FORM,
    payload: form,
  };
};
