import { combineReducers } from 'redux';
import undoable from 'redux-undo';

import UserReducer from './userReducer';
import OrganizationReducer from './organizationReducer';
import ApplicationReducer from './applicationReducer';
import OrganizationUserReducer from './organizationUserReducer';
import StorageSettingReducer from './storageSettingReducer';
import FormReducer from './formReducer';

export default combineReducers({
  user: UserReducer,
  organization: OrganizationReducer,
  organizationUsers: OrganizationUserReducer,
  storageSettings: StorageSettingReducer,
  application: ApplicationReducer,
  form: undoable(FormReducer),
});
