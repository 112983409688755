function EditMenu({ itemClicked, itemSelected }) {
  return (
    <>
      <div className="item-container" onClick={() => itemClicked('undo')}>
        <i className="fas fa-undo-alt"></i>
        Undo
      </div>
      <div className="item-container" onClick={() => itemClicked('redo')}>
        <i className="fas fa-redo-alt"></i>
        Redo
      </div>
      {/* <div className="item-container">
        <i className="fas fa-cut"></i>
        Cut
      </div>
      <div className="item-container">
        <i className="fas fa-copy"></i>
        Copy
      </div>
      <div className="item-container">
        <i className="fas fa-paste"></i>
        Paste
      </div> */}
      <div className={`item-container${itemSelected ? '' : ' disabled'}`} onClick={() => itemClicked('duplicate')}>
        <i className="fas fa-clone"></i>
        Duplicate
      </div>
      <div className={`item-container${itemSelected ? '' : ' disabled'}`} onClick={() => itemClicked('delete')}>
        <i className="fas fa-trash"></i>
        Delete
      </div>
    </>
  );
}

export default EditMenu;
