import { useState } from 'react';

import './FormBuilderSidebar.scss';
import FormBuilderContent from '../FormBuilderContent';

function FormBuilderSidebar({
  pages,
  currentPageIndex,
  onPageChange,
  globalTheme,
  globalStyles,
  globalCustomCSS,
}) {
  const [open, setOpen] = useState(true);

  return (
    <div className="FormBuilderSidebar">
      <div className={`sidebar-spacer${open ? '' : ' closed'}`}></div>

      <div className={`sidebar-inner${open ? '' : ' closed'}`}>
        <div className="sidebar-main-container">
          {pages.map((page, i) => {
            return (
              <div
                key={`page-tile-${i}`}
                className={`page-tile-container${currentPageIndex === i ? ' active' : ''}`}
                onClick={() => onPageChange(i)}
              >
                <div className="page-tile">
                  <div className="page-tile-cover"></div>

                  <FormBuilderContent
                    page={page}
                    globalTheme={globalTheme}
                    globalStyles={globalStyles}
                    globalCustomCSS={globalCustomCSS}
                    isThumbnail={true}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="sidebar-footer">
          <div className="sidebar-footer-left"></div>
          <div className="sidebar-close-button-container">
            <button onClick={() => setOpen(false)}>
              <i className="fa fa-chevron-left"></i>
            </button>
          </div>

          <div className={`sidebar-open-button-container ${open ? 'hide' : ''}`}>
            <button onClick={() => setOpen(true)}>
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormBuilderSidebar;
