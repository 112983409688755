import './Loading.scss';

function Loading({ position, scale }) {
  return (
    <div className="Loading">
      <div className="main-loading-container" style={{ position: position || 'fixed' }}>
        <div className="main-loading-container-inner">
          <div className="main-loading-bars-container">
            <div className="loading-bars" style={{ transform: `scale(${scale ? scale : 1})` }}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
