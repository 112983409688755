import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './FolderTile.scss';
import ContextMenu from '../../../shared/ContextMenu';
import Modal from '../../../shared/Modal';
import useLongPress from '../../../../hooks/useLongPress';

const defaultOptions = {
  shouldPreventDefault: false,
  delay: 500,
};

function FolderTile({ folder, onEditClicked, confirmDelete }) {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const routeToFolder = (e) => {
    history.push(`/forms/${folder.path}`);
  };

  const openMenu = (e) => {
    e.preventDefault();

    let x = 0;
    let y = 0;

    if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
      const event = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
      const touch = event.touches[0] || event.changedTouches[0];

      x = touch.clientX;
      y = touch.clientY;
    } else {
      x = e.clientX;
      y = e.clientY;
    }

    setTop(y);
    setLeft(x);
    setTimeout(() => {
      setMenuOpen(true);
    }, 0);
  };

  const longPressEvent = useLongPress(openMenu, routeToFolder, defaultOptions);

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleConfirmDelete = () => {
    handleDeleteConfirmationClose();
    confirmDelete();
  };

  return (
    <>
      <a {...longPressEvent} className="folder-tile-link">
        <div
          onContextMenu={openMenu}
          className="FolderTile"
        >
          <div className="folder-image">
            <i className="fas fa-folder-open"></i>
          </div>
          <div className="folder-name">{folder.name}</div>
        </div>
      </a>

      <ContextMenu
        open={menuOpen}
        top={top}
        left={left}
        close={() => setMenuOpen(false)}
      >
        <div className="FolderTileContextMenu" onClick={() => setMenuOpen(false)}>
          <a href={`/forms/${folder.path}`} target="_blank">
            <div className="item-container">
              <i className="fas fa-external-link-alt"></i>
              Open
            </div>
          </a>
          <div className="item-container edit" onClick={onEditClicked}>
            <i className="fas fa-edit"></i>
            Edit
          </div>
          <div className="item-container delete" onClick={() => setDeleteConfirmationOpen(true)}>
            <i className="fas fa-trash"></i>
            Delete
          </div>
        </div>
      </ContextMenu>

      <Modal
        open={deleteConfirmationOpen}
        close={handleDeleteConfirmationClose}
        title="Delete Folder?"
        buttons={[
          <button
            key="modal-confirm"
            className="small gradient modal-confirm"
            onClick={handleConfirmDelete}
          >
            Confirm
          </button>,
          <button
            key="modal-close"
            className="small modal-close"
            onClick={handleDeleteConfirmationClose}
          >
            Cancel
          </button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete {folder.name}?</div>
        </div>
      </Modal>
    </>
  );
}

export default FolderTile;
