import cloneDeep from 'lodash.clonedeep';
import { shortAnswer } from './inputAnswers';

export const defaultForm = {
  id: '',
  title: '',
  parentFolder: '',
  customPath: '',
  restrictAccess: false,
  allowedUsers: [],
  lastUpdatedBy: '',
  organizationId: '',
  globalCustomCSS: '',
  globalStyles: {
    itemsPaddingVertical: 10,
    maxWidth: 700,
  },
  pages: [{
    customCSS: '',
    layout: 'center',
    separateBackgrounds: false, // used for non centered layout
    enableBoxShadow: true, // used for centered layout
    styles: {
      itemsPaddingVertical: '',
      maxWidth: '',
      formBackgroundColor: '#fff',
      backgroundColor: '#fff',
      backgroundImage: '',
      primaryBackgroundColor: '#fff', // used for non centered layout
      primaryBackgroundImage: '', // used for non centered layout
      secondaryBackgroundColor: '#fff', // used for non centered layout
      secondaryBackgroundImage: '', // used for non centered layout
    },
    sections: [cloneDeep({ ...shortAnswer, added: `${Date.now()}` })],
  }],
  created: 0,
  updated: 0,
  deleted: null,
};
