import { useState } from 'react';

import './FormBuilderHeader.scss';
import MoveFolder from '../../../images/icons/move-folder.svg';
import ContextMenu from '../../shared/ContextMenu';
import FileMenu from './FileMenu';
import EditMenu from './EditMenu';
import ViewMenu from './ViewMenu';
import InsertMenu from './InsertMenu';
import FormatMenu from './FormatMenu';
import PageMenu from './PageMenu';
import LayoutMenu from './LayoutMenu';

const menuItems  = [
  'File',
  'Edit',
  'View',
  'Insert',
  'Format',
  'Page',
  // 'Help',
];

function FormBuilderHeader({
  title,
  onTitleChange,
  backPressed,
  savePressed,
  addNewPage,
  duplicatePage,
  deletePage,
  openBackgroundModal,
  insertItem,
  changePageLayout,
  editItemClicked,
  undoChange,
  redoChange,
  currentSelectedSectionIndex,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [menuType, setMenuType] = useState('');

  const openMenu = (e, type, topDistance, leftDistance) => {
    e.preventDefault();

    const rect = e.target.getBoundingClientRect();
    let x = 0;
    let y = 0;

    if (topDistance || leftDistance) {
      x = leftDistance;
      y = topDistance
    } else {
      x = rect.left;
      y = window.pageYOffset + (rect.top + rect.height);
    }

    setTop(y);
    setLeft(x);
    setMenuType(type);
    setTimeout(() => {
      setMenuOpen(true);
    }, 0);
  };

  const pageItemClicked = (e, type) => {
    if (type === 'new-page') {
      openMenu(e, type, top, left);
    } else if (type === 'change-layout') {
      openMenu(e, type, top, left);
    } else if (type === 'duplicate-page') {
      duplicatePage();
    } else if (type === 'delete-page') {
      deletePage();
    } else if (type === 'change-background') {
      openBackgroundModal();
    }
  };

  const getCurrentMenu = () => {
    switch (menuType) {
      case 'new-page':
        return (
          <LayoutMenu
            layoutSelected={(layout) => addNewPage(layout)}
          />
        );
      case 'change-layout':
        return (
          <LayoutMenu
            layoutSelected={(layout) => changePageLayout(layout)}
          />
        );
      case 'file':
        return (
          <FileMenu/>
        );
      case 'edit':
        return (
          <EditMenu
            itemClicked={editItemClicked}
            itemSelected={currentSelectedSectionIndex !== -1}
          />
        );
      case 'view':
        return (
          <ViewMenu/>
        );
      case 'insert':
        return (
          <InsertMenu itemClicked={insertItem} />
        );
      case 'format':
        return (
          <FormatMenu/>
        );
      case 'page':
        return (
          <PageMenu itemClicked={pageItemClicked} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`FormBuilderHeader${open ? '' : ' closed'}`}>
        <div className="header-top">
          <div className="header-left">
            <div className="back-button-container">
              <button className="gradient" onClick={backPressed}>
                <i className="fas fa-arrow-left"></i>
              </button>
            </div>

            <div className="menu-container">
              <div className="menu-top-row">
                <div className="name-container">
                  <div className="name-filler">{title || 'Untitled form'}</div>
                  <input
                    type="text"
                    value={title}
                    onChange={onTitleChange}
                    placeholder="Untitled form"
                  />
                </div>

                <div className="icon-buttons-container">
                  <div>
                    <button>
                      <img src={MoveFolder} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="menu-bottom-row">
                {menuItems.map(item => {
                  return (
                    <div className="menu-item" key={item}>
                      <button
                        className="menu-button"
                        onClick={(e) => openMenu(e, item.toLowerCase())}
                      >
                        {item}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="header-right">
            <button className="secondary share-button">
              Share
              <i className="fas fa-share"></i>
            </button>
            <button className="alternate" onClick={savePressed}>
              <i className="fas fa-save"></i>
              Save
            </button>
          </div>
        </div>

        <div className="header-bottom">
          <div className="header-bottom-left">
            <div className="menu-item">
              <button
                className="menu-button new-page-button large"
                onClick={(e) => openMenu(e, 'new-page')}
              >
                + New Page
              </button>
              <button
                className="menu-button new-page-button medium"
                onClick={(e) => openMenu(e, 'new-page')}
              >
                + Page
              </button>
              <button
                className="menu-button new-page-button small"
                onClick={(e) => openMenu(e, 'new-page')}
              >
                +
              </button>
            </div>
            <div className="menu-item">
              <button className="menu-button" onClick={undoChange}>
                <i className="fas fa-undo-alt"></i>
              </button>
            </div>
            <div className="menu-item">
              <button className="menu-button" onClick={redoChange}>
                <i className="fas fa-redo-alt"></i>
              </button>
            </div>
            <div className="menu-pipe"></div>
            <div className="menu-item">
              <button className="menu-button">
                Layout
              </button>
            </div>
            <div className="menu-pipe"></div>
            <div className="menu-item">
              <button className="menu-button">
                Theme
              </button>
            </div>
            <div className="menu-pipe"></div>
            <div className="menu-item">
              <button className="menu-button">
                Logic
              </button>
            </div>
          </div>

          <div className="header-bottom-right">
            <div className="header-close-button-container">
              <button onClick={() => setOpen(false)}>
                <i className="fa fa-chevron-up"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`header-open-button-container ${open ? 'hide' : ''}`}>
        <button onClick={() => setOpen(true)}>
          <i className="fa fa-chevron-down"></i>
        </button>
      </div>

      <ContextMenu
        open={menuOpen}
        top={top}
        left={left}
        close={() => setMenuOpen(false)}
        isMenu={true}
        preventScrollBlock={true}
      >
        <div className="FolderTileContextMenu" onClick={() => setMenuOpen(false)}>
          {getCurrentMenu()}
        </div>
      </ContextMenu>
    </>
  );
}

export default FormBuilderHeader;
