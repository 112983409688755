import { useRef } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';

import './FormBuilderContent.scss';
import LongAnswer from './formItemComponents/LongAnswer';
import InputAnswer from './formItemComponents/InputAnswer';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

function FormBuilderContent({
  page = {},
  globalTheme,
  globalStyles,
  globalCustomCSS,
  updateSections = () => {},
  isThumbnail,
  currentSelectedSectionIndex,
  updateCurrentSelectedSectionIndex,
}) {
  const formItemsRef = useRef(null);
  const formBuilderRef = useRef(null);
  useOnClickOutside(formItemsRef, () => updateCurrentSelectedSectionIndex(-1), formBuilderRef.current);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedSections = reorder(
      page.sections,
      result.source.index,
      result.destination.index
    );

    updateSections(updatedSections);
  };

  const updateField = (value, type, i) => {
    const updatedSections = [ ...page.sections ];

    updatedSections[i] = {
      ...updatedSections[i],
      [type]: value,
    };

    updateSections(updatedSections);
  };

  const deleteSection = (i) => {
    const updatedSections = [ ...page.sections ];

    updatedSections.splice(i, 1);

    updateSections(updatedSections);
  };

  const renderSection = (s, i) => {
    const type = s.type;

    if (type === 'text' || type === 'email' || type === 'number' || type === 'url') {
      return (
        <InputAnswer
          data={s}
          updateField={(value, type) => updateField(value, type, i)}
        />
      );
    } else if (type === 'long-answer') {
      return (
        <LongAnswer
          data={s}
          updateField={(value, type) => updateField(value, type, i)}
        />
      );
    }
  };

  return (
    <div className={`FormBuilderContent${isThumbnail ? ' thumbnail' : ''}`} ref={formBuilderRef}>
      <div className="form-builder-content-inner">
        <div
          className={`content-card${page.layout === 'center' ? '' : ` ${page.layout}`}`}
          style={{
            backgroundColor: page.layout !== 'center' && page.separateBackgrounds ? 'rgba(0, 0, 0, 0)' : page.styles.backgroundColor,
          }}
        >
          {!page.layout ? null :
            <>
              <div
                className="layout-container"
                style={{
                  backgroundColor: page.layout === 'center' || !page.separateBackgrounds ? 'rgba(0, 0, 0, 0)' : page.styles.primaryBackgroundColor,
                }}
              ></div>
              <div
                className="form-items-container-outer"
                style={{
                  backgroundColor: page.layout === 'center' || !page.separateBackgrounds ? 'rgba(0, 0, 0, 0)' : page.styles.secondaryBackgroundColor,
                }}
              >
                <div
                  className="form-items-container"
                  style={{
                    maxWidth: page.styles.maxWidth || globalStyles.maxWidth || '100%',
                    backgroundColor: page.layout !== 'center' ? 'rgba(0, 0, 0, 0)' : page.styles.formBackgroundColor,
                    boxShadow: page.layout !== 'center' || !page.enableBoxShadow ? '' : '0 4px 4px rgba(87, 100, 126, 0.2)',
                  }}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div ref={formItemsRef}>
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {page.sections.map((s, i) => {
                              return (
                                <Draggable key={s.added} draggableId={s.added} index={i}>
                                  {(provided, snapshot) => (
                                    <div
                                      className={`section-item-container${currentSelectedSectionIndex === i ? ' selected' : ''}`}
                                      onClick={() => updateCurrentSelectedSectionIndex(i)}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="section-item-actions-container">
                                        <button
                                          className="section-item-action-button"
                                        >
                                          <i className="fas fa-edit"></i>
                                        </button>

                                        <button
                                          className="section-item-action-button"
                                          onClick={() => deleteSection(i)}
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      </div>
                                      <div
                                        style={{
                                          paddingTop: page.styles.itemsPaddingVertical || globalStyles.itemsPaddingVertical,
                                          paddingBottom: page.styles.itemsPaddingVertical || globalStyles.itemsPaddingVertical,
                                        }}
                                      >
                                        {renderSection(s, i)}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default FormBuilderContent;
