import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';

import './FormBuilder.scss';
import FormBuilderHeader from './FormBuilderHeader';
import FormBuilderSidebar from './FormBuilderSidebar';
import FormBuilderContent from './FormBuilderContent';
import FormBuilderBackgroundModal from './FormBuilderBackgroundModal';
import Modal from '../shared/Modal';
import useScrollBlock from '../../hooks/useScrollBlock';
import { defaultForm, formTypesMap } from './formConfig';
import { config } from '../../config';
import {
  setForm,
  setStorageSettings,
} from '../../store/actions';

function FormBuilder({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storageSettings = useSelector(state => state.storageSettings);
  const form = useSelector(state => state.form.present);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [containerHeight, setContainerHeight] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showBackgroundColorModal, setShowBackgroundColorModal] = useState(false);
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] = useState(-1);

  useEffect(() => {
    window.addEventListener('resize', resetHeight);
    blockScroll();

    dispatch(ActionCreators.clearHistory());

    setTimeout(() => {
      resetHeight();
    });

    fetchOrganizationStorage();

    return () => {
      allowScroll();
      window.removeEventListener('resize', resetHeight);
    };
  }, []);

  useEffect(() => {
    if (!form.pages[currentPageIndex] && form.pages[currentPageIndex - 1]) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  }, [form.pages.length]);

  const fetchOrganizationStorage = async () => {
    if (storageSettings.id) {
      return;
    }

    const auth = getAuth();
    const currentUser = auth.currentUser;
    let token = '';

    try {
      token = await currentUser.getIdToken(true);
    } catch (e) {
      auth.signOut();
      return;
    }

    try {
      const response = await axios.get(`${config.api}/v1/storage`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response || !response.data) {
        createOrganizationStorage(token);
        return;
      }

      dispatch(setStorageSettings(response.data));
    } catch (e) {
      console.log('fetchOrganizationStorage error', e);
    }
  };

  const createOrganizationStorage = async (token) => {
    try {
      const response = await axios.post(`${config.api}/v1/storage`, {
        region: 'LA',
        replicationRegions: ['DE'],
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(setStorageSettings(response.data.storageSettings));
    } catch (e) {
      console.log('createOrganizationStorage error', e);
    }
  };

  const resetHeight = () => {
    const newHeight = window.innerHeight;
    setContainerHeight(newHeight);
  };

  const handleTextChange = (e, type) => {
    dispatch(setForm({
      ...form,
      [type]: e.target.value,
    }));
  };

  const addNewPage = (layout) => {
    const index = currentPageIndex + 1;
    const pages = [ ...form.pages ];
    const newPage = {
      ...defaultForm.pages[0],
      layout,
      sections: [{
        ...defaultForm.pages[0].sections[0],
        added: `${Date.now()}`,
      }],
    };

    pages.splice(index, 0, cloneDeep(newPage));

    dispatch(setForm({
      ...form,
      pages,
    }));
    setCurrentPageIndex(index);
  };

  const duplicatePage = () => {
    const index = currentPageIndex + 1;
    const pages = [ ...form.pages ];
    const newPage = cloneDeep(pages[currentPageIndex]);

    pages.splice(index, 0, newPage);

    dispatch(setForm({
      ...form,
      pages,
    }));
    setCurrentPageIndex(index);
  };

  const deletePage = () => {
    const pages = [ ...form.pages ];

    pages.splice(currentPageIndex, 1);

    dispatch(setForm({
      ...form,
      pages,
    }));
  };

  const insertItem = (type) => {
    const itemToInsert = formTypesMap[type];

    if (!itemToInsert) {
      return;
    }

    const pages = [ ...form.pages ];

    const sections = [ ...pages[currentPageIndex].sections ];
    sections.push(cloneDeep({ ...itemToInsert, added: `${Date.now()}` }));

    pages[currentPageIndex] = {
      ...pages[currentPageIndex],
      sections,
    };

    dispatch(setForm({
      ...form,
      pages,
    }));
  };

  const changePageLayout = (layout) => {
    const pages = [ ...form.pages ];

    pages[currentPageIndex] = {
      ...pages[currentPageIndex],
      layout,
    };

    dispatch(setForm({
      ...form,
      pages,
    }));
  };

  const updateSections = (sections) => {
    const pages = [ ...form.pages ];

    pages[currentPageIndex] = {
      ...pages[currentPageIndex],
      sections,
    };

    dispatch(setForm({
      ...form,
      pages,
    }));
  };

  const handleBackgroundChange = (data) => {
    const pages = [ ...form.pages ];

    if (data.type === 'color') {
      const field = data.field;
      pages[currentPageIndex] = {
        ...pages[currentPageIndex],
        styles: {
          ...pages[currentPageIndex].styles,
          [field]: data.color,
        },
      };
    } else if (data.type === 'separate-background') {
      pages[currentPageIndex] = {
        ...pages[currentPageIndex],
        separateBackgrounds: data.value,
      };
    } else if (data.type === 'enable-box-shadow') {
      pages[currentPageIndex] = {
        ...pages[currentPageIndex],
        enableBoxShadow: data.value,
      };
    }

    dispatch(setForm({
      ...form,
      pages,
    }));
  };

  const handleEditItemClicked = (type) => {
    if (type === 'undo') {
      undo();
    } else if (type === 'redo') {
      redo();
    } else if (type === 'duplicate') {
      const sections = [ ...form.pages[currentPageIndex].sections ];

      const itemToInsert = {
        ...sections[currentSelectedSectionIndex],
        added: `${Date.now()}`,
      };

      sections.splice(currentSelectedSectionIndex, 0, cloneDeep(itemToInsert));

      updateSections(sections);
    } else if (type === 'delete') {
      const sections = [ ...form.pages[currentPageIndex].sections ];

      sections.splice(currentSelectedSectionIndex, 1);

      setCurrentSelectedSectionIndex(-1);

      setTimeout(() => {
        updateSections(sections);
      }, 0);
    }
  };

  const undo = () => {
    dispatch(ActionCreators.undo());
  };

  const redo = () => {
    dispatch(ActionCreators.redo());
  };

  return (
    <div className="FormBuilder"  style={{ height: containerHeight ? `${containerHeight}px` : '100vh' }}>
      <div className="form-builder-inner">
        <FormBuilderHeader
          title={form.title}
          onTitleChange={(e) => handleTextChange(e, 'title')}
          backPressed={() => history.goBack()}
          savePressed={() => console.log('save')}
          addNewPage={addNewPage}
          duplicatePage={duplicatePage}
          deletePage={deletePage}
          openBackgroundModal={() => setShowBackgroundColorModal(true)}
          insertItem={insertItem}
          changePageLayout={changePageLayout}
          editItemClicked={handleEditItemClicked}
          undoChange={() => undo()}
          redoChange={() => redo()}
          currentSelectedSectionIndex={currentSelectedSectionIndex}
        />

        <div className="main-container">
          <div className="main-container-inner">
            <FormBuilderSidebar
              pages={form.pages}
              currentPageIndex={currentPageIndex}
              onPageChange={(index) => setCurrentPageIndex(index)}
              globalCustomCSS={form.globalCustomCSS}
              globalStyles={form.globalStyles}
            />
            <FormBuilderContent
              page={form.pages[currentPageIndex]}
              globalCustomCSS={form.globalCustomCSS}
              globalStyles={form.globalStyles}
              updateSections={updateSections}
              currentSelectedSectionIndex={currentSelectedSectionIndex}
              updateCurrentSelectedSectionIndex={(index) => setCurrentSelectedSectionIndex(index)}
            />
          </div>
        </div>

        <Modal
          open={showBackgroundColorModal}
          close={() => {
            setShowBackgroundColorModal(false);
          }}
          title="Background"
          buttons={[
            <button key="apply-to-theme" className="small gradient" onClick={() => {
            }}>Apply to Theme</button>,
            <button key="modal-close" className="small" onClick={() => {
              setShowBackgroundColorModal(false);
            }}>Done</button>,
          ]}
        >
          <div>
            <FormBuilderBackgroundModal
              backgroundColor={(form.pages[currentPageIndex] && form.pages[currentPageIndex].styles.backgroundColor) || '#fff'}
              formBackgroundColor={(form.pages[currentPageIndex] && form.pages[currentPageIndex].styles.formBackgroundColor) || '#fff'}
              primaryBackgroundColor={(form.pages[currentPageIndex] && form.pages[currentPageIndex].styles.primaryBackgroundColor) || '#fff'}
              secondaryBackgroundColor={(form.pages[currentPageIndex] && form.pages[currentPageIndex].styles.secondaryBackgroundColor) || '#fff'}
              layout={(form.pages[currentPageIndex] && form.pages[currentPageIndex].layout)}
              separateBackgrounds={(form.pages[currentPageIndex] && form.pages[currentPageIndex].separateBackgrounds)}
              enableBoxShadow={(form.pages[currentPageIndex] && form.pages[currentPageIndex].enableBoxShadow)}
              onBackgroundChange={handleBackgroundChange}
            />
          </div>
        </Modal>

        <Modal
          open={!!modalText}
          close={() => {
            setModalTitle('');
            setModalText('');
          }}
          title={modalTitle}
          buttons={[
            <button key="modal-close" className="small" onClick={() => {
              setModalTitle('');
              setModalText('');
            }}>Close</button>,
          ]}
        >
          <div>
            <div className="modal-text">{modalText}</div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default FormBuilder;
