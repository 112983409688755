import {
  SET_ORGANIZATION_USERS,
} from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case SET_ORGANIZATION_USERS:
      return action.payload;
    default:
      return state;
  }
};
