import './Integrations.scss';

function Integrations({}) {
  return (
    <div className="Integrations">
      Integrations
    </div>
  );
}

export default Integrations;
