import './LayoutMenu.scss';
import LongText from '../../../../images/icons/long-text.svg';

function LayoutMenu({ layoutSelected }) {
  return (
    <div className="LayoutMenu">
      <button className="option-container" onClick={() => layoutSelected('left')}>
        <div className="left-container">
          <div className="left"></div>
          <div className="right">
            <img src={LongText} />
          </div>
        </div>
      </button>
      <button className="option-container" onClick={() => layoutSelected('center')}>
        <div className="center-container">
          <img src={LongText} />
        </div>
      </button>
      <button className="option-container" onClick={() => layoutSelected('right')}>
        <div className="right-container">
          <div className="left">
            <img src={LongText} />
          </div>
          <div className="right"></div>
        </div>
      </button>
    </div>
  );
}

export default LayoutMenu;
