function ViewMenu({}) {
  return (
    <>
      <div className="item-container">
        <i className="far fa-eye"></i>
        Preview
      </div>
      <div className="item-container edit">
        <i className="fas fa-columns"></i>
        Themes
      </div>
      <div className="item-container edit">
        <i className="fas fa-th"></i>
        Grid View
      </div>
    </>
  );
}

export default ViewMenu;
