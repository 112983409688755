import './Billing.scss';

function Billing({}) {
  return (
    <div className="Billing">
      Billing
    </div>
  );
}

export default Billing;
