import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import './ContextMenu.scss';
import useScrollBlock from '../../../hooks/useScrollBlock';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

function ContextMenu({ open, close, children, top = 0, left = 0, isMenu, preventScrollBlock }) {
  const menuRef = useRef(null);
  const [blockScroll, allowScroll] = useScrollBlock();
  useOnClickOutside(menuRef, () => close());
  const [topDistance, setTopDistance] = useState(0);
  const [leftDistance, setLeftDistance] = useState(0);

  useEffect(() => {
    setTopDistance(top);
    setLeftDistance(left);

    setTimeout(() => {
      if (menuRef.current) {
        const rect = menuRef.current.getBoundingClientRect();
        const rightOverflow = (window.innerWidth || document.documentElement.clientWidth) - (rect.x + rect.width);
        const bottomOverflow = (window.innerHeight || document.documentElement.clientHeight) - rect.bottom;

        if (rightOverflow < 0) {
          setLeftDistance(left + rightOverflow - 5);
        }

        if (bottomOverflow < 0) {
          if (!isMenu) {
            setTopDistance(top + bottomOverflow - 5);
          } else {
            const newHeight = rect.height + bottomOverflow - 5;
            menuRef.current.style.height = `${newHeight}px`;
          }
        }
      }
    }, 0);
  }, [top, left, menuRef.current]);

  useEffect(() => {
    if (preventScrollBlock) {
      return;
    }

    if (open) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [open, preventScrollBlock]);

  if (!open) {
    return <div></div>;
  }

  return ReactDOM.createPortal(
    <div className="ContextMenu" style={{ position: isMenu ? 'inherit' : 'fixed' }}>
      <div
        ref={menuRef}
        className="menu-body"
        style={{
          top: `${topDistance}px`,
          left: `${leftDistance}px`,
        }}
      >
        {children}
      </div>
    </div>,
    document.querySelector('#modalTarget')
  );
}

export default ContextMenu;
