import {
  shortAnswer,
  emailAnswer,
  numberAnswer,
  urlAnswer,
} from './inputAnswers';
import { longAnswer } from './longAnswer';

export const formTypesMap = {
  'email': emailAnswer,
  'text': shortAnswer,
  'number': numberAnswer,
  'long-answer': longAnswer,
  'url': urlAnswer,
};
