import {
  SET_LOADING,
} from '../actions/types';

const defaultState = {
  loading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
