const inputAnswerFields = {
  label: '',
  labelAlign: 'left',
  placeholder: 'Enter response...',
  subLabel: '',
  subLabelAlign: 'left',
  hoverText: '',
  defaultValue: '',
  readOnly: false,
  required: false,
  autoWidth: true,
  customWidth: 150,
  validation: 'none',
  characterLimit: false,
  minCharacters: 50,
  maxCharacters: 100,
  maskedInput: false,
  inputMask: '',
  columns: 12,
  hasOwnLine: false,
  hideField: false,
  uniqueName: '',
  classes: '',
  styles: '',
  logic: [], // used to change next page or questions
};

export const shortAnswer = {
  ...inputAnswerFields,
  type: 'text',
};

export const emailAnswer = {
  ...inputAnswerFields,
  type: 'email',
  placeholder: 'someone@example.com',
};

export const numberAnswer = {
  ...inputAnswerFields,
  type: 'number',
  placeholder: '123456',
};

export const urlAnswer = {
  ...inputAnswerFields,
  type: 'url',
  placeholder: 'https://',
};
