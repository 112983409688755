import { useEffect, useState, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

import './AssetPickerModal.scss';
import Loading from '../Loading';
import { config } from '../../../config';

const organizationAssetsDirectory = 'assets/';
const baseStyle = {
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function AssetPickerModal({ open, close, title, handleFileSelect }) {
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length) {
      uploadFiles(acceptedFiles);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  useEffect(() => {
    if (open) {
      fetchDirectoryContents();
    }
  }, [open]);

  const fetchDirectoryContents = async () => {
    setLoadingFiles(true);

    const auth = getAuth();
    const currentUser = auth.currentUser;
    let token = '';

    try {
      token = await currentUser.getIdToken(true);
    } catch (e) {
      setFiles([]);
      setLoadingFiles(false);
      return;
    }

    try {
      const response = await axios.get(`${config.api}/v1/storage/directory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          directory: organizationAssetsDirectory,
        },
      });

      const filesToSet = (response.data || []).sort((a, b) => {
        return new Date(b.DateCreated) - new Date(a.DateCreated);
      }).map(file => {
        if (!file.IsDirectory) {
          file.url = `https://${file.StorageZoneName}.b-cdn.net${file.Path.replace(`/${file.StorageZoneName}`, '') + file.ObjectName}`;
        }

        return file;
      });

      console.log(response.data);
      setFiles(filesToSet);
      setLoadingFiles(false);
    } catch (e) {
      console.log('fetchDirectoryContents error', e);
      setFiles([]);
      setLoadingFiles(false);
      close();
    }
  };

  const uploadFiles = async (filesToUpload) => {
    setLoadingFiles(true);

    const auth = getAuth();
    const currentUser = auth.currentUser;
    let token = '';

    try {
      token = await currentUser.getIdToken(true);
    } catch (e) {
      setFiles([]);
      setLoadingFiles(false);
      return;
    }

    try {
      const formData = new FormData();

      filesToUpload.forEach(file => {
        formData.append('files', file);
      });

      await axios.post(`${config.api}/v1/storage/directory/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        params: {
          directory: organizationAssetsDirectory,
        },
      });

      fetchDirectoryContents();
    } catch (e) {
      console.log('uploadFiles error', e);
      setLoadingFiles(false);
    }
  };

  if (!open) {
    return <div></div>;
  }

  return ReactDOM.createPortal(
    <div className="AssetPickerModal">
      <div className="modal-body">
        <div className="close-button" onClick={close}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>

        {!title ? null : <h3 className="title">{title}</h3>}

        <div className="modal-content">
          {!loadingFiles ? null :
            <div className="loading-indicator-container">
              <Loading
                position="fixed"
                scale={0.75}
              />
            </div>
          }

          <div className="items-container">
            <div className="item-container" style={!files.length ? { width: '100%', height: 200 } : {}}>
              <div className="item-outer add-button" style={!files.length ? { paddingTop: 0, height: 200 } : {}}>
                <div className="item">
                  <div className="add-item-button" {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <div>
                      <p>Upload Files</p>
                      <i className="fas fa-upload"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {files.map((file, i) => {
              if (file.IsDirectory) {
                return;
              }

              return (
                <div className="item-container" key={`file-${i}`}>
                  <div className="item-outer">
                    <div className="item">
                      <div
                        className="item-image-container"
                        onClick={() => handleFileSelect(file.url)}
                      >
                        <img src={file.url} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="buttons-container">
        </div>
      </div>
    </div>,
    document.querySelector('#modalTarget')
  );
}

export default AssetPickerModal;
