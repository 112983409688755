import './Account.scss';

function Account({}) {
  return (
    <div className="Account">
      Account
    </div>
  );
}

export default Account;
