import { useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import './FormBuilderBackgroundModal.scss';
import ContextMenu from '../../shared/ContextMenu';
import AssetPickerModal from '../../shared/AssetPickerModal';

function FormBuilderBackgroundModal({
  backgroundColor,
  formBackgroundColor,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  layout,
  separateBackgrounds,
  enableBoxShadow,
  onBackgroundChange,
}) {
  const [color, setColor] = useColor('hex', backgroundColor);
  const [formColor, setFormColor] = useColor('hex', formBackgroundColor);
  const [mainColor, setMainColor] = useColor('hex', secondaryBackgroundColor);
  const [alternateColor, setAlternateColor] = useColor('hex', primaryBackgroundColor);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [currentColorType, setCurrentColorType] = useState('backgroundColor');
  const [currentAssetPickerType, setCurrentAssetPickerType] = useState('');
  const [assetPickerModalOpen, setAssetPickerModalOpen] = useState(false);

  const updateColor = () => {
    let colorToSet = color.hex;

    if (currentColorType === 'primaryBackgroundColor') {
      colorToSet = alternateColor.hex;
    } else if (currentColorType === 'secondaryBackgroundColor') {
      colorToSet = mainColor.hex;
    } else if (currentColorType === 'formBackgroundColor') {
      colorToSet = formColor.hex;
    }

    onBackgroundChange({
      type: 'color',
      field: currentColorType,
      color: colorToSet,
    });
  };

  const openMenu = (type) => {
    setCurrentColorType(type);

    let x = 0;
    let y = 0;
    
    x = window.innerWidth / 2 - 125;
    y = window.innerHeight / 2 - 125;

    setTop(y);
    setLeft(x);
    setTimeout(() => {
      setShowColorPicker(true);
    }, 0);
  };

  const renderPicker = () => {
    if (currentColorType === 'backgroundColor') {
      return <ColorPicker width={250} height={125} color={color} onChange={setColor} hideHSV hideRGB alpha />;
    } else if (currentColorType === 'formBackgroundColor') {
      return <ColorPicker width={250} height={125} color={formColor} onChange={setFormColor} hideHSV hideRGB alpha />;
    } else if (currentColorType === 'primaryBackgroundColor') {
      return <ColorPicker width={250} height={125} color={alternateColor} onChange={setAlternateColor} hideHSV hideRGB alpha />;
    } else {
      return <ColorPicker width={250} height={125} color={mainColor} onChange={setMainColor} hideHSV hideRGB alpha />;
    }
  };

  return (
    <div className="FormBuilderBackgroundModal">
      {layout === 'center' ? null :
        <div className="background-modal-row">
          <div className="separate-background-toggle-container">
            <div
              className={`separate-background-toggle-inner${separateBackgrounds ? ' active' : ''}`}
              onClick={() => {
                onBackgroundChange({
                  type: 'separate-background',
                  value: !separateBackgrounds,
                });
              }}
            >
              <div className="separate-background-toggle-handle">
              </div>
            </div>

            <div>Use different backgrounds</div>
          </div>
        </div>
      }
      {layout !== 'center' && separateBackgrounds ?
        <>
          <div className="background-modal-row">
            <div>Form Background Color</div>

            <div>
              <button
                className="small"
                onClick={() => openMenu('secondaryBackgroundColor')}
              >
                <div className="color-preview" style={{ backgroundColor: mainColor.hex }}></div>
                <i className="fa fa-chevron-down"></i>
              </button>
            </div>
          </div>

          <div className="background-modal-row">
            <div>Form Background Image</div>

            <div>
              <button
                className="small"
                onClick={() => {
                  setCurrentAssetPickerType('primaryBackgroundImage');
                  setAssetPickerModalOpen(true);
                }}
              >
                Choose Image
              </button>
            </div>
          </div>

          <div className="background-modal-row">
            <div>Alternate Background Color</div>

            <div>
              <button
                className="small"
                onClick={() => openMenu('primaryBackgroundColor')}
              >
                <div className="color-preview" style={{ backgroundColor: alternateColor.hex }}></div>
                <i className="fa fa-chevron-down"></i>
              </button>
            </div>
          </div>

          <div className="background-modal-row">
            <div>Alternate Background Image</div>

            <div>
              <button
                className="small"
                onClick={() => {
                  setCurrentAssetPickerType('secondaryBackgroundImage');
                  setAssetPickerModalOpen(true);
                }}
              >
                Choose Image
              </button>
            </div>
          </div>
        </> :
        <>
          {layout !== 'center' ? null :
            <div className="background-modal-row">
              <div className="separate-background-toggle-container">
                <div
                  className={`separate-background-toggle-inner${enableBoxShadow ? ' active' : ''}`}
                  onClick={() => {
                    onBackgroundChange({
                      type: 'enable-box-shadow',
                      value: !enableBoxShadow,
                    });
                  }}
                >
                  <div className="separate-background-toggle-handle">
                  </div>
                </div>

                <div>Box shadow enabled</div>
              </div>
            </div>
          }

          <div className="background-modal-row">
            <div>Background Color</div>

            <div>
              <button
                className="small"
                onClick={() => openMenu('backgroundColor')}
              >
                <div className="color-preview" style={{ backgroundColor: color.hex }}></div>
                <i className="fa fa-chevron-down"></i>
              </button>
            </div>
          </div>

          {layout !== 'center' ? null :
            <>
              <div className="background-modal-row">
                <div>Form Color</div>

                <div>
                  <button
                    className="small"
                    onClick={() => openMenu('formBackgroundColor')}
                  >
                    <div className="color-preview" style={{ backgroundColor: formColor.hex }}></div>
                    <i className="fa fa-chevron-down"></i>
                  </button>
                </div>
              </div>
            </>
          }

          <div className="background-modal-row">
            <div>Background Image</div>

            <div>
              <button
                className="small"
                onClick={() => {
                  setCurrentAssetPickerType('backgroundImage');
                  setAssetPickerModalOpen(true);
                }}
              >
                Choose Image
              </button>
            </div>
          </div>
        </>
      }

      <ContextMenu
        open={showColorPicker}
        top={top}
        left={left}
        close={() => {
          updateColor()
          setShowColorPicker(false);
        }}
        preventScrollBlock={true}
      >
        <div className="color-picker-container">
          {renderPicker()}
        </div>
      </ContextMenu>

      <AssetPickerModal
        open={assetPickerModalOpen}
        close={() => {
          setAssetPickerModalOpen(false);
        }}
        title="Insert Background Image"
      />
    </div>
  );
}

export default FormBuilderBackgroundModal;
