import ThreeColumns from '../../../../images/icons/three-columns.svg';

function PageMenu({ itemClicked }) {
  return (
    <>
      <div className="item-container edit" onClick={(e) => itemClicked(e, 'new-page')}>
        <i className="fas fa-plus-square"></i>
        New page
      </div>
      <div className="item-container edit" onClick={(e) => itemClicked(e, 'duplicate-page')}>
        <i className="fas fa-clone"></i>
        Duplicate page
      </div>
      <div className="item-container edit" onClick={(e) => itemClicked(e, 'delete-page')}>
        <i className="fas fa-trash"></i>
        Delete page
      </div>
      <div className="item-container edit" onClick={(e) => itemClicked(e, 'change-background')}>
        <i className="fas fa-palette"></i>
        Change Background
      </div>
      <div className="item-container edit" onClick={(e) => itemClicked(e, 'change-layout')}>
        <img alt="" src={ThreeColumns}/>
        Change Layout
      </div>
    </>
  );
}

export default PageMenu;
