export const longAnswer = {
  type: 'long-answer',
  label: '',
  labelAlign: 'left',
  placeholder: 'Enter response...',
  rows: 2,
  subLabel: '',
  subLabelAlign: 'left',
  hoverText: '',
  defaultValue: '',
  readOnly: false,
  required: false,
  autoWidth: true,
  customWidth: 150,
  validation: 'none',
  characterLimit: false,
  minCharacters: 50,
  maxCharacters: 100,
  maskedInput: false,
  inputMask: '',
  columns: 12,
  hasOwnLine: false,
  hideField: false,
  uniqueName: '',
  classes: '',
  styles: '',
  resize: 'none',
  logic: [], // used to change next page or questions
};
