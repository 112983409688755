import {
  SET_LOADING,
} from './types';

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading,
  };
};
