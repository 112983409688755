import './Team.scss';

function Team({}) {
  return (
    <div className="Team">
      <div className="tab-container">
        <div className="header">
          <h1>Team</h1>

          <button className="gradient">+ Add Team Member</button>
        </div>
      </div>
    </div>
  );
}

export default Team;
