import '../textFieldItemStyle.scss';

function LongAnswer({ data, updateField }) {
  return (
    <div className="textFieldItemStyle">
      <input
        className="label-input"
        placeholder="Type a question..."
        value={data.label}
        onChange={(e) => updateField(e.target.value, 'label')}
        style={{
          textAlign: data.labelAlign,
        }}
      />
      <div className="drag-input-container">
        <div className="input-cover"></div>
        <textarea
          id={`long-answer-${data.added}`}
          name={`long-answer-${data.added}`}
          aria-describedby={`description-${data.added}`}
          placeholder={data.placeholder}
        ></textarea>
      </div>
      <input
        className="sublabel-input"
        placeholder="Add some help text..."
        value={data.subLabel}
        onChange={(e) => updateField(e.target.value, 'subLabel')}
        style={{
          textAlign: data.subLabelAlign,
        }}
      />
    </div>
  );
}

export default LongAnswer;
