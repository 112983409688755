import {
  SET_STORAGE_SETTINGS,
} from './types';

export const setStorageSettings = (settings) => {
  return {
    type: SET_STORAGE_SETTINGS,
    payload: settings,
  };
};
