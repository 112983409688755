function FormatMenu({}) {
  return (
    <>
      <div className="item-container">
        <i className="fas fa-font"></i>
        Text
      </div>
      <div className="item-container edit">
        <i className="fas fa-align-left"></i>
        Align & Indent
      </div>
      <div className="item-container edit">
        <i className="fas fa-text-height"></i>
        Spacing
      </div>
      <div className="item-container edit">
        <i className="fas fa-remove-format"></i>
        Clear formatting
      </div>
      <div className="item-container edit">
        <i className="fas fa-border-style"></i>
        Borders
      </div>
      <div className="item-container edit">
        <i className="fas fa-palette"></i>
        Background
      </div>
      <div className="item-container edit">
        <i className="fas fa-image"></i>
        Image
      </div>
    </>
  );
}

export default FormatMenu;
