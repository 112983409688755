import ShortText from '../../../../images/icons/short-text.svg';
import LongText from '../../../../images/icons/long-text.svg';
import RadioButton from '../../../../images/icons/radio-button.svg';
import Scale from '../../../../images/icons/scale.svg';
import Bars from '../../../../images/icons/bars.svg';

function InsertMenu({ itemClicked }) {
  return (
    <>
      <div className="item-container" onClick={() => itemClicked('text')}>
        <img alt="" src={ShortText}/>
        Short Answer
      </div>
      <div className="item-container" onClick={() => itemClicked('long-answer')}>
        <img alt="" src={LongText}/>
        Long Answer
      </div>
      <div className="item-container" onClick={() => itemClicked('multiple-choice')}>
        <i className="fas fa-check-square"></i>
        Multiple Choice
      </div>
      <div className="item-container" onClick={() => itemClicked('single-choice')}>
        <img alt="" src={RadioButton}/>
        Single Choice
      </div>
      <div className="item-container" onClick={() => itemClicked('dropdown')}>
        <i className="fas fa-chevron-down"></i>
        Dropdown
      </div>
      <div className="item-container" onClick={() => itemClicked('number')}>
        <i className="fas fa-hashtag"></i>
        Number
      </div>
      <div className="item-container" onClick={() => itemClicked('input-table')}>
        <i className="fas fa-th"></i>
        Input Table
      </div>
      <div className="item-container" onClick={() => itemClicked('phone')}>
        <i className="fas fa-phone"></i>
        Phone Number
      </div>
      <div className="item-container" onClick={() => itemClicked('email')}>
        <i className="fas fa-envelope"></i>
        Email address
      </div>
      <div className="item-container" onClick={() => itemClicked('picture-choice')}>
        <i className="fas fa-images"></i>
        Picture Choice
      </div>
      <div className="item-container" onClick={() => itemClicked('ranking')}>
        <img alt="" src={Bars}/>
        Ranking
      </div>
      <div className="item-container" onClick={() => itemClicked('opinion-scale')}>
        <img alt="" src={Scale}/>
        Opinion Scale
      </div>
      <div className="item-container" onClick={() => itemClicked('yes-no')}>
        <i className="fas fa-thumbs-up"></i>
        Yes/No
      </div>
      <div className="item-container" onClick={() => itemClicked('rating')}>
        <i className="fas fa-star"></i>
        Rating
      </div>
      <div className="item-container" onClick={() => itemClicked('url')}>
        <i className="fas fa-link"></i>
        Website
      </div>
      <div className="item-container" onClick={() => itemClicked('date')}>
        <i className="far fa-calendar"></i>
        Date
      </div>
      <div className="item-container" onClick={() => itemClicked('image')}>
        <i className="fas fa-image"></i>
        Image
      </div>
      <div className="item-container" onClick={() => itemClicked('header')}>
        <i className="fas fa-heading"></i>
        Header
      </div>
      <div className="item-container" onClick={() => itemClicked('paragraph')}>
        <i className="fas fa-font"></i>
        Paragraph
      </div>
      <div className="item-container" onClick={() => itemClicked('divider')}>
        <i className="fas fa-grip-lines"></i>
        Divider
      </div>
    </>
  );
}

export default InsertMenu;
