import { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import './Forms.scss';
import Modal from '../../shared/Modal';
import FolderTile from './FolderTile';
import { config } from '../../../config';
import { setLoading, setOrganizationUsers } from '../../../store/actions';

const pathRegex = "^[A-Za-z0-9\-]+$";
const accessOptions = [
  { value: false, label: 'Allow All' },
  { value: true, label: 'Restricted' },
];

function Forms({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const organizationUsers = useSelector(state => state.organizationUsers);
  const location = useLocation();
  const history = useHistory();
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [folders, setFolders] = useState([]);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [editFolder, setEditFolder] = useState({
    id: '',
    name: '',
    path: '',
    parentFolder: '',
    restrictAccess: false,
    allowedUsers: [],
    lastUpdatedBy: '',
    organizationId: '',
    created: 0,
    updated: 0,
    deleted: null,
  });
  const [selectedAccessOption, setSelectedAccessOption] = useState(null);
  const [selectedAllowedUsersOptions, setSelectedAllowedUsersOptions] = useState(null);
  const [allowedUsersOptions, setAllowedUsersOptions] = useState([]);
  const [parentPath, setParentPath] = useState('');

  useEffect(() => {
    setAllowedUsersOptions(organizationUsers.map(u => {
      return { value: u.email, label: u.email };
    }));
  }, [organizationUsers]);

  useEffect(() => {
    if (!location.pathname.includes('/forms')) {
      history.replace('/forms');
      return;
    }

    if (location.pathname[location.pathname.length - 1] === '/') {
      history.replace(location.pathname.substring(0, location.pathname.length - 1));
    }

    fetchFiles();
  }, [location.pathname]);

  const fetchFiles = async () => {
    dispatch(setLoading(true));

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken(true);
      let parentFolder = '';

      if (location.pathname !== '/forms') {
        parentFolder = location.pathname.split('/').splice(2).join('/');
      }

      const folderResult = await axios.post(`${config.api}/v1/folders/get-by-parent-path`, {
        parentFolder,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedFolders = folderResult.data.sort((a, b) => {
        return a.created - b.created;
      });

      if (!organizationUsers.length) {
        const usersResult = await axios.get(`${config.api}/v1/users/organization`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        dispatch(setOrganizationUsers(usersResult.data));
      }

      setFolders(sortedFolders);
      setParentPath(parentFolder);
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      let errorMessage = 'There was an error retrieving your forms. Please try again. If this problem persists, contact us for assistance.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }
  };

  const saveFolder = async () => {
    dispatch(setLoading(true));

    try {
      const auth = getAuth();
      const email = auth.currentUser.email;
      const token = await auth.currentUser.getIdToken(true);
      let parentFolder = '';

      if (location.pathname !== '/forms') {
        parentFolder = location.pathname.split('/').splice(2).join('/');
      }

      const allowedUsers = [ ...editFolder.allowedUsers ];

      if (editFolder.restrictAccess && !allowedUsers.includes(email)) {
        allowedUsers.push(email);
      }

      if (!editFolder.id) {
        let folderData = {
          parentFolder,
          path: `${parentFolder ? `${parentFolder}/` : ''}${editFolder.path}`,
          name: editFolder.name,
          restrictAccess: editFolder.restrictAccess,
          allowedUsers,
          lastUpdatedBy: email,
        };
  
        const result = await axios.post(`${config.api}/v1/folder`, {
          ...folderData,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        setFolders([ ...folders, result.data ]);
      } else {
        let folderData = {
          parentFolder,
          path: `${parentFolder ? `${parentFolder}/` : ''}${editFolder.path}`,
          name: editFolder.name,
          restrictAccess: editFolder.restrictAccess,
          allowedUsers,
          created: editFolder.created,
        };
  
        const result = await axios.put(`${config.api}/v1/folder/${editFolder.id}`, {
          ...folderData,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('result', result);

        const updatedFolders = [ ...folders ];
        const folderIndex = folders.findIndex(f => f.id === editFolder.id);

        updatedFolders[folderIndex] = result.data;
  
        setFolders(updatedFolders);
      }
      
      dispatch(setLoading(false));
      closeFolderModal();
    } catch (e) {
      dispatch(setLoading(false));
      let errorMessage = 'There was an error saving your folder, please try again. If this problem persists, contact us for assistance.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }
  };

  const closeFolderModal = () => {
    setShowFolderModal(false);
    setEditFolder({
      id: '',
      name: '',
      path: '',
      parentFolder: '',
      restrictAccess: false,
      allowedUsers: [],
      lastUpdatedBy: '',
      organizationId: '',
      created: 0,
      updated: 0,
      deleted: null,
    });
    setSelectedAccessOption({ value: false, label: 'Allow All' });
    setSelectedAllowedUsersOptions([]);
  };

  const deleteFolder = async (i) => {
    dispatch(setLoading(true));

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken(true);
      await axios.delete(`${config.api}/v1/folder/${folders[i].id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedFolders = [ ...folders ];
      updatedFolders.splice(i, 1);

      setFolders(updatedFolders);
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      let errorMessage = 'There was an error deleting this folder, please try again. If this problem persists, contact us for assistance.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }
  };

  const editFolderClicked = (i) => {
    const folder = folders[i];
    const accessOption = folder.restrictAccess ?
      { value: true, label: 'Restricted' } :
      { value: false, label: 'Allow All' };

    setShowFolderModal(true);
    setEditFolder({ ...folder });
    setSelectedAccessOption(accessOption);
    setSelectedAllowedUsersOptions(folder.allowedUsers.map(u => {
      return { value: u, label: u };
    }));
  };

  const renderBreadcrumbs = () => {
    const pathArr = location.pathname.split('/');
    pathArr.shift();

    return (
      <>
        {pathArr.map((path, i) => {
          if (i === pathArr.length - 1) {
            return (
              <div className="breadcrumb-item-container" key={`breadcrumb-item-${i}`}>
                <span className="breadcrumb-item">{path}</span>
              </div>
            );
          }

          return (
            <div className="breadcrumb-item-container" key={`breadcrumb-item-${i}`}>
              <Link to={`/${pathArr.slice(0, i + 1).join('/')}`} className="breadcrumb-item">{path}</Link>
              <div className="breadcrumb-item">/</div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="Forms">
      <div className="breadcrumbs-container">
        {location.pathname === '/forms' ?
          <div className="breadcrumb-item-container">
            <span className="breadcrumb-item">Home Folder</span>
          </div> :
          renderBreadcrumbs()
        }
      </div>

      <div className="top-row">
        <div className="header-container">
          <h1>Forms</h1>
        </div>

        <div className="buttons-container">
          <Link to={`/form-builder${parentPath ? `?${parentPath}` : ''}`}>
            <button className="gradient">
              + Create Form
            </button>
          </Link>

          {location.pathname !== '/forms' ? null :
            <button onClick={() => {
              setSelectedAccessOption({ value: false, label: 'Allow All' });
              setSelectedAllowedUsersOptions([]);
              setShowFolderModal(true);
            }} className="add-folder">
              + Add Folder
            </button>
          }
        </div>
      </div>

      <div className="items-container">
        {folders.map((folder, i) => {
          if (folder.restrictAccess && !folder.allowedUsers.includes(user.email)) {
            return null;
          }

          return (
            <div key={folder.id} className="item-container">
              <FolderTile
                folder={folder}
                confirmDelete={() => deleteFolder(i)}
                onEditClicked={() => editFolderClicked(i)}
              />
            </div>
          );
        })}
      </div>

      <Modal
        open={showFolderModal}
        title={`${editFolder.id ? 'Edit' : 'Create'} Folder`}
        close={closeFolderModal}
        buttons={[
          <button
            key="modal-save"
            className="small gradient"
            disabled={!editFolder.name || !editFolder.path || !editFolder.path.match(pathRegex) || (editFolder.restrictAccess && !editFolder.allowedUsers.length)}
            onClick={saveFolder}
          >
            Save Folder
          </button>,
          <button
            key="modal-close"
            className="small"
            onClick={closeFolderModal}
          >
            Cancel
          </button>,
        ]}
      >
        <div className="edit-folder-modal">
          <div className="input-container">
            <label>Folder Name:</label>
            <input
              type="text"
              value={editFolder.name}
              onChange={(e) => {
                setEditFolder({
                  ...editFolder,
                  name: e.target.value,
                });
              }}
              placeholder="Folder Name"
            />
          </div>

          <div className="input-container">
            <label>Folder Path:</label>
            <input
              type="text"
              value={editFolder.path}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '');
                setEditFolder({
                  ...editFolder,
                  path: value,
                });
              }}
              placeholder="Folder Path"
            />
            <small>Only letters, number, and hyphens allowed</small>
          </div>

          <div className="input-container">
            <label>Team Access:</label>
            <Select
              styles={{
                control: (styles) => ({ ...styles, paddingTop: .5, paddingBottom: .5 }),
              }}
              value={selectedAccessOption}
              onChange={(option) => {
                setSelectedAccessOption(option);
                setEditFolder({
                  ...editFolder,
                  restrictAccess: option.value,
                });
              }}
              options={accessOptions}
            />
          </div>

          {!editFolder.restrictAccess ? null :
            <div className="input-container">
              <label>Allowed Team Members:</label>
              <Select
                styles={{
                  control: (styles) => ({ ...styles, paddingTop: .5, paddingBottom: .5 }),
                }}
                value={selectedAllowedUsersOptions}
                isMulti
                onChange={(value) => {
                  setSelectedAllowedUsersOptions(value);
                  setEditFolder({
                    ...editFolder,
                    allowedUsers: value.map(u => u.value),
                  });
                }}
                options={allowedUsersOptions}
              />
            </div>
          }
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Forms;
